<template>
  <div v-if="user">
    <!-- <div class="agreement-top d-none d-md-flex">
      <div class="back-icon">
        <i class="fa fa-arrow-left"></i>
      </div>
      <div class="page-number">
        <a class="page-one active">1</a>
        <div class="slash">/</div>
        <a class="page-two">2</a>
      </div>
      <div class="next-icon">
        <i class="fa fa-arrow-right"></i>
      </div>
      <button
        type="button"
        class="custom-btn2"
        @click="saveAsPDF(0)"
        :disabled="isLoadingArray[0]"
      >
        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
        <span v-else>Save as PDF</span>
      </button>
    </div> -->
    <div class="e-sign-wrapper" v-if="embeddedSignUrl">
      <iframe
        ref="iframeContent"
        frameborder="0"
        height="900"
        scrolling="yes"
        :src="embeddedSignUrl + '#toolbar=0'"
        width="100%"
        id="sigForm"
      >
      </iframe>
      <div
        style="font-family: Helvetica, Arial, Verdana, sans-serif;line-height:13px !important;text-align:center;margin-top: 6px !important;"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NDAFormAgreement",
  data: function() {
    return {
      isLoadingArray: [],
      mobilePreview: true,
      details: {},
      embeddedSignUrl: ""
    };
  },
  components: {
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    htmlToPdfOptions() {
      return {
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          useCORS: true,
        },
      };
    },
  },
  methods: {
    saveAsPDF: function(index) {
      this.$set(this.isLoadingArray, index, true);
      this.$refs.html2Pdf.generatePdf();
      setTimeout(() => {
        this.$set(this.isLoadingArray, index, false);
      }, 500);
    },
  },
  mounted() {
    this.$root.$on("ndaAgreeTable", (data) => {
      this.details = data;
      if(data && data.agreement && data.agreement.signature){
        this.embeddedSignUrl = data.agreement.signature; 
      }
    });
    this.$root.$on("companiesView", (data) => {
      this.details = data;
      if(data && data.agreement && data.agreement.signature){
        this.embeddedSignUrl = data.agreement.signature; 
      }
    });
},
 
};
</script>

<style></style>
